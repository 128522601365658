.student-list-container {
  width: 100vw;
  height: auto;
  padding: 10px 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.student-list-container .head h1 {
  font-size: 32px;
  color: rgb(96, 11, 11);
  text-transform: uppercase;
}

.student-list-container .student-list {
  width: 100%;
  height: auto;
  justify-content: center;
  gap: 60px;
  display: flex;
}

.student-list-container .student-list .items .heading h1{
  width: 100%;
  height: auto;
  font-size:20px;
  text-align: center;
  margin: 15px 0px 0px 0px;
  background: #4d0707;
  padding: 10px 0px;
  color: white;
}

.student-list-container .student-list .items table {
  width: 100%;
  height: auto;
  border-collapse: collapse;
}

.student-list-container .student-list .items table th {
  font-size: 20px;
  color: #fffefe;
  background: #2e1301;
  border:2px solid #ffffff;
  padding: 6px 40px;
}
.student-list-container .student-list .items table td {
  width: auto;
  text-align: center;
  font-size: 18px;
  color: rgb(64, 7, 7);
  padding: 8px;
  border:2px solid #ffffff;
}

.student-list-container .student-list .items table tr:nth-child(odd){
    background:#f8ba91;
    border:2px solid #ffffff;
}

.student-list-container .student-list .items table tr:nth-child(even){
    background:#f6d6c1;
    border:2px solid #ffffff;
}

/* Grade notes:- */

/* Notes Container */
.student-list-container .notes_grade {
  width:auto;
  margin: 40px 0px;
  flex-direction: column;
  align-items: center;
  justify-content:center;
  display: flex;
  background: #fff;
  padding: 20px 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Header */
.student-list-container .head {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #4d0707;
}

/* Notes List */
.student-list-container .notes {
  width:100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  gap:20px;
}

/* Individual Note Item */
.student-list-container .list {
  width:100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
  background: #f0f0f0;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.303);
}

/* Grade Text */
.student-list-container  .list h3 {
  margin: 0;
  color: #333;
}

/* Grade Range */
.student-list-container .list span {
  font-size: 15px;
  color: #666;
}


/* Common styles for all devices */

/* Small mobile devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .student-list-container .notes{
    flex-wrap: wrap;
  }
  .student-list-container .head h1 {
    font-size: 20px;
    padding: 0px 10px;
    text-align: center;
  }
  
  .student-list-container .student-list {
    width:100%;
    height: auto;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    display: flex;
  }
  
  .student-list-container .student-list .items .heading h1{
    margin: 12px 0px 0px 0px;
    font-size: 20px;
  }
  
  .student-list-container .student-list .items table {
    width: 100%;
    height: auto;
    border-collapse: collapse;
  }
  
  .student-list-container .student-list .items table th {
    font-size:15px;
  }
  .student-list-container .student-list .items table td {
    font-size: 14px;
  }

}

/* Normal mobile devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {

  .student-list-container .head h1 {
    font-size: 22px;
    padding: 0px 10px;
    text-align: center;
  }
  
  .student-list-container .student-list {
    width:95%;
    height: auto;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    display: flex;
  }
  
  .student-list-container .student-list .items .heading h1{
    margin: 12px 0px 0px 0px;
    font-size: 20px;
  }
  
  .student-list-container .student-list .items table {
    width: 100%;
    height: auto;
    border-collapse: collapse;
  }
  
  .student-list-container .student-list .items table th {
    font-size:18px;
  }
  .student-list-container .student-list .items table td {
    font-size: 16px;
  }
}

/* Tablets and iPads (portrait and landscape, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {

  .student-list-container .head h1 {
    font-size: 22px;
    padding: 0px 10px;
    text-align: center;
  }
  
  .student-list-container .student-list {
    width:95%;
    height: auto;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
    display: flex;
  }
  
  .student-list-container .student-list .items .heading h1{
    margin: 12px 0px 0px 0px;
    font-size: 20px;
  }
  
  .student-list-container .student-list .items table {
    width: 100%;
    height: auto;
    border-collapse: collapse;
  }
  
  .student-list-container .student-list .items table th {
    font-size:18px;
  }
  .student-list-container .student-list .items table td {
    font-size: 16px;
  }
}

/* Tablets and iPads (landscape, larger than 992px) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .student-list-container .head h1 {
    font-size: 22px;
    padding: 0px 10px;
    text-align: center;
  }
  
  .student-list-container .student-list {
    width:95%;
    height: auto;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
    display: flex;
  }
  
  .student-list-container .student-list .items .heading h1{
    margin: 12px 0px 0px 0px;
    font-size: 20px;
  }
  
  .student-list-container .student-list .items table {
    width: 100%;
    height: auto;
    border-collapse: collapse;
  }
  
  .student-list-container .student-list .items table th {
    font-size:18px;
  }
  .student-list-container .student-list .items table td {
    font-size: 16px;
  }
}

/* Laptops and PCs (larger than 1200px) */
@media (min-width: 1200px) and (max-width: 1439.98px) {

}

/* Large screens (TVs and larger displays, 1440px and up) */
@media (min-width: 1440px) {
  /* Add your styles here */
}