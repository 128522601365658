.MoreSection-container{
    width: 100vw;
    height: auto;
    display:flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    flex-direction: column;
}
.MoreSection-container .heading{
   color:white;
   text-align: center;
   width: 100%;
   font-size: 20px;
   padding:6px 0px;
   background: #7c0303;
}
.MoreSection-container .MoreSection{
    width: 100%;
    max-width: 1920px;
    height: auto;
    display:flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
    gap: 14px;
}
.MoreSection-container .MoreSection .items{
    width:auto;
    height: auto;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 5px;
    color: #ffffff;
    font-weight:500;
    cursor: pointer;
    background: rgb(146, 17, 17);
}

.MoreSection-container .MoreSection .items svg{
    width:30px;
    fill: #ffffff;
    height:auto;
}

.MoreSection-container .MoreSection .items:hover{
    background:#2d2b2b;
    color: white;
}

.MoreSection-container .MoreSection .items:hover svg{
   fill: white;
}


/* Common styles for all devices */

/* Small mobile devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

    .MoreSection-container .MoreSection{
        flex-direction: column;
        padding: 10px 0px;
        gap: 14px;
    }
    .MoreSection-container .MoreSection .items{
        gap: 10px;
    }
    
}

/* Normal mobile devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {

    .MoreSection-container .MoreSection{
        flex-direction: column;
        padding: 10px 0px;
        gap: 14px;
    }
    .MoreSection-container .MoreSection .items{
        gap: 10px;
    }
    
}

/* Tablets and iPads (portrait and landscape, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {

    .MoreSection-container .MoreSection{
        flex-direction: row;
        padding: 10px 0px;
        gap: 14px;
    }
    .MoreSection-container .MoreSection .items{
        gap: 10px;
    }
    
}

/* Tablets and iPads (landscape, larger than 992px) */
@media (min-width: 992px) and (max-width: 1199.98px) {

}

/* Laptops and PCs (larger than 1200px) */
@media (min-width: 1200px) and (max-width: 1439.98px) {

}

/* Large screens (TVs and larger displays, 1440px and up) */
@media (min-width: 1440px) {
  /* Add your styles here */
}