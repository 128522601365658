.admin-logout-container {
  width: 60px;
  height: 60px;
  background: #510707;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top:85vh;
  right: 40px;
  border-radius: 50%;
  cursor: pointer;
}
.admin-logout-container svg {
  width: 60%;
  fill: white;
}

/* Common styles for all devices */

/* Small mobile devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .admin-logout-container {
    width: 45px;
    height:45px;
    top:2vh;
    right: 20px;
    background:white;
  }

  .admin-logout-container svg {
    fill: rgb(77, 5, 5);
  }
}

/* Normal mobile devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .admin-logout-container {
    width: 45px;
    height:45px;
    top:2vh;
    right: 20px;
    background:white;
  }

  .admin-logout-container svg {
    fill: rgb(77, 5, 5);
  }
}

/* Tablets and iPads (portrait and landscape, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}
