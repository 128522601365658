/* Basic styling for the hero section */
.attendance-hero-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .attendance-hero-container .attendance-hero {
    background-color: #2f4f4f;
    color: white;
    padding: 40px;
    border-radius: 10px;
    width: 100%;
    max-width: 600px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .attendance-hero-container .head h1 {
    font-size: 36px;
    margin-bottom: 20px;
    font-weight: 700;
    color: #ffcc00;
  }
  
  .attendance-hero-container .wish {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .time {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 10px;
  }
  
  .wishing {
    font-size: 20px;
    color: #471709;
    font-weight: 500;
    text-align: center;
    padding: 10px;
    border-radius: 8px;
    background-color: #c8f918;
    width: 80%;
    margin-top: 10px;
  }
  
  /* Add subtle transition effects */
  .wishing {
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .wishing:hover {
    color: rgb(255, 255, 255);
    background-color: #29110c;
    transform: scale(1.05);
  }

  
/* Common styles for all devices */

/* Small mobile devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
/* Basic styling for the hero section */
.attendance-hero-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
  }
  
  .attendance-hero-container .attendance-hero {
    padding: 20px;
    border-radius: 0px;
    width: 100%;
  }
  
  .attendance-hero-container .head h1 {
    font-size: 24px;
  }
  

  
  .time {
    font-size: 22px;
  }
  
  .wishing {
    font-size:18px;
  }

}

/* Normal mobile devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {

}

/* Tablets and iPads (portrait and landscape, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {

}

/* Tablets and iPads (landscape, larger than 992px) */
@media (min-width: 992px) and (max-width: 1199.98px) {

}

/* Laptops and PCs (larger than 1200px) */
@media (min-width: 1200px) and (max-width: 1439.98px) {

}

/* Large screens (TVs and larger displays, 1440px and up) */
@media (min-width: 1440px) {
  /* Add your styles here */
}
  