.Filter-student-list-container {
  width: 100vw;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
}
.Filter-student-list-container .heading {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  background: #680606;
}
.Filter-student-list-container .heading h1 {
  color: #fff;
  text-transform: capitalize;
}
.Filter-student-list-container .Filter-student-list {
  width: 95%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Filter-student-list-container .Filter-student-list .excel-download {
  width: auto;
  height: auto;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  gap: 10px;
  align-self: flex-end;
  padding: 4px 15px;
  cursor: pointer;
  border: #015702 1px solid;
  font-size: 17px;
  font-weight: bold;
}
.Filter-student-list-container .Filter-student-list .excel-download:hover {
  background: #f1f1f1;
  border: #013302 1px solid;
}
.Filter-student-list-container .Filter-student-list .excel-download img {
  width: 30px;
}

/*Filter option style*/
.Filter-student-list-container .Filter-list-container,
.Filter-student-list-container .Filter-list-container .filter-options {
  width: 100vw;
  height: auto;
  display: flex;
  align-items: center;
  gap: 18px;
  justify-content: center;
  background: #901414;
  margin: 14px 0px;
}

.Filter-student-list-container .Filter-list-container .filter-options .items {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.Filter-student-list-container
  .Filter-list-container
  .filter-options
  .items:nth-child(3)
  select {
  width: 100px;
}

.Filter-student-list-container
  .Filter-list-container
  .filter-options
  .items
  select {
  width: 180px;
  height: 40px;
  cursor: pointer;
  background: transparent;
  color: white;
}

.Filter-student-list-container
  .Filter-list-container
  .filter-options
  .items
  option {
  width: 180px;
  height: 40px;
  cursor: pointer;
  background: transparent;
  color: rgb(51, 9, 9);
  padding: 4px;
  font-size: 16px;
}

.Filter-student-list-container
  .Filter-list-container
  .filter-options
  .items
  input {
  width: 180px;
  height: 40px;
  cursor: pointer;
  background: transparent;
  color: rgb(255, 255, 255);
  padding-left: 4px;
}

.Filter-student-list-container
  .Filter-list-container
  .filter-options
  .items
  input[type="text"] {
  padding: 5px;
}

.Filter-student-list-container
  .Filter-list-container
  .filter-options
  .items
  input::placeholder {
  color: rgb(255, 255, 255);
  padding: 5px;
}

.Filter-student-list-container
  .Filter-list-container
  .filter-options
  .items
  label {
  font-size: 17px;
  color: rgb(255, 255, 255);
  font-weight: 500;
}

.Filter-student-list-container
  .Filter-list-container
  .filter-options
  .items
  button:hover {
  background: rgb(255, 255, 255);
  color: black;
}

.Filter-student-list-container
  .Filter-list-container
  .filter-options
  .items
  button {
  font-size: 16px;
  width: auto;
  padding: 5px 15px;
  cursor: pointer;
  height: 35px;
  background: transparent;
  border: 1px white solid;
  border-radius: 5px;
  color: white;
}

.Filter-student-list-container
  .Filter-list-container
  .filter-options
  .items
  .from-date {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.Filter-student-list-container
  .Filter-list-container
  .filter-options
  .items
  .to-date {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.student-list-results {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.student-list-results table {
  width: 1000px;
  border-collapse: collapse;
  text-align: center;
}

.student-list-results table th {
  padding:5px;
  color: white;
  border: #6b0707 1px solid;
  background: #650202;
}

.student-list-results table td {
  padding:5px;
  color: rgb(0, 0, 0);
  border: #6b0707 1px solid;
}

.student-list-results table tr:nth-child(even) {
  padding:5px;
  background: #f8cece;
}

.student-list-results table tr:nth-child(odd) {
  padding:5px;
  background: #ffb3b3;
}



/* Common styles for all devices */

/* Small mobile devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .Filter-student-list-container {
       gap: 10px;
      }
      .Filter-student-list-container .heading {
        width: 100%;
        height: 53px;
        padding: 10px 0px;
      }
      .Filter-student-list-container .heading h1 {
        font-size: 18px;
      }
      .Filter-student-list-container .Filter-student-list {
        width: 97%
      }
      .Filter-student-list-container .Filter-student-list .excel-download {
        gap: 8px;
        font-size: 14px;
      }

      
      /*Filter option style*/
      .Filter-student-list-container .Filter-list-container,
      .Filter-student-list-container .Filter-list-container .filter-options {
        width:98%;
        padding: 0px 10px;
        align-items: flex-start;
        flex-direction: column;
        border-radius: 15px;
        gap: 8px;
      }
      
      .Filter-student-list-container .Filter-list-container .filter-options .items {
        width:100%;
        height: auto;
        flex-direction: column;
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: center;
      }
      
      .Filter-student-list-container
        .Filter-list-container
        .filter-options
        .items:nth-child(3)
        select {
        width:100%;
      }
      
      .Filter-student-list-container
        .Filter-list-container
        .filter-options
        .items
        select {
        width:100%;
        height:39px;
      }
      
      .Filter-student-list-container
        .Filter-list-container
        .filter-options
        .items
        option {
        width:100%;
        height: 40px;
        font-size: 12px;
      }
      
      .Filter-student-list-container
        .Filter-list-container
        .filter-options
        .items
        input {
        width:100%;
        height:36px;
        font-size: 14px
      }
      

      .Filter-student-list-container
        .Filter-list-container
        .filter-options
        .items
        label {
        font-size: 15px;
      }
      

      
      .Filter-student-list-container
        .Filter-list-container
        .filter-options
        .items
        button {
        font-size: 16px;
        width: 100%;
      }

      
      .student-list-results table {
        width: 100%;
      }
      
      .student-list-results table th {
        padding:4px;
        font-size: 15px;
      }
      
      .student-list-results table td {
        padding:4px;
        font-size: 14px;
      }
      
}

/* Normal mobile devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .Filter-student-list-container {
        gap: 10px;
       }
       .Filter-student-list-container .heading {
         width: 100%;
         height: 53px;
         padding: 10px 0px;
       }
       .Filter-student-list-container .heading h1 {
         font-size: 18px;
       }
       .Filter-student-list-container .Filter-student-list {
         width: 97%
       }
       .Filter-student-list-container .Filter-student-list .excel-download {
         gap: 8px;
         font-size: 14px;
       }
 
       
       /*Filter option style*/
       .Filter-student-list-container .Filter-list-container,
       .Filter-student-list-container .Filter-list-container .filter-options {
         width:98%;
         padding: 0px 10px;
         align-items: flex-start;
         flex-direction: column;
         border-radius: 15px;
         gap: 8px;
       }
       
       .Filter-student-list-container .Filter-list-container .filter-options .items {
         width:100%;
         height: auto;
         flex-direction: column;
         display: flex;
         align-items: center;
         gap: 10px;
         justify-content: center;
       }
       
       .Filter-student-list-container
         .Filter-list-container
         .filter-options
         .items:nth-child(3)
         select {
         width:100%;
       }
       
       .Filter-student-list-container
         .Filter-list-container
         .filter-options
         .items
         select {
         width:100%;
         height:39px;
       }
       
       .Filter-student-list-container
         .Filter-list-container
         .filter-options
         .items
         option {
         width:100%;
         height: 40px;
         font-size: 12px;
       }
       
       .Filter-student-list-container
         .Filter-list-container
         .filter-options
         .items
         input {
         width:100%;
         height:36px;
         font-size: 14px
       }
       
 
       .Filter-student-list-container
         .Filter-list-container
         .filter-options
         .items
         label {
         font-size: 15px;
       }
       
 
       
       .Filter-student-list-container
         .Filter-list-container
         .filter-options
         .items
         button {
         font-size: 16px;
         width: 100%;
       }
 
       
       .student-list-results table {
         width: 100%;
       }
       
       .student-list-results table th {
         padding:4px;
         font-size: 15px;
       }
       
       .student-list-results table td {
         padding:4px;
         font-size: 14px;
       }
       
}

/* Tablets and iPads (portrait and landscape, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .Filter-student-list-container {
        gap: 10px;
       }
       .Filter-student-list-container .heading {
         width: 100%;
         height: 53px;
         padding: 10px 0px;
       }
       .Filter-student-list-container .heading h1 {
         font-size: 18px;
       }
       .Filter-student-list-container .Filter-student-list {
         width: 97%
       }
       .Filter-student-list-container .Filter-student-list .excel-download {
         gap: 8px;
         font-size: 14px;
       }
 
       
       /*Filter option style*/
       .Filter-student-list-container .Filter-list-container,
       .Filter-student-list-container .Filter-list-container .filter-options {
         width:98%;
         padding: 0px 10px;
         align-items: flex-start;
         flex-direction: row;
         border-radius: 15px;
         gap: 8px;
       }
       
       .Filter-student-list-container .Filter-list-container .filter-options .items {
         width:100%;
         height: auto;
         flex-direction: row;
         display: flex;
         align-items: center;
         gap: 10px;
         justify-content: center;
       }
       
       .Filter-student-list-container
         .Filter-list-container
         .filter-options
         .items:nth-child(3)
         select {
         width:100%;
       }
       
       .Filter-student-list-container
         .Filter-list-container
         .filter-options
         .items
         select {
         width:100%;
         height:39px;
       }
       
       .Filter-student-list-container
         .Filter-list-container
         .filter-options
         .items
         option {
         width:100%;
         height: 40px;
         font-size: 12px;
       }
       
       .Filter-student-list-container
         .Filter-list-container
         .filter-options
         .items
         input {
         width:100%;
         height:36px;
         font-size: 14px
       }
       
 
       .Filter-student-list-container
         .Filter-list-container
         .filter-options
         .items
         label {
         font-size: 15px;
       }
       
 
       
       .Filter-student-list-container
         .Filter-list-container
         .filter-options
         .items
         button {
         font-size: 16px;
         width: 100%;
       }
 
       
       .student-list-results table {
         width: 100%;
       }
       
       .student-list-results table th {
         padding:4px;
         font-size: 15px;
       }
       
       .student-list-results table td {
         padding:4px;
         font-size: 14px;
       }
       
}

/* Tablets and iPads (landscape, larger than 992px) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    .Filter-student-list-container {
        gap: 10px;
       }
       .Filter-student-list-container .heading {
         width: 100%;
         height: 53px;
         padding: 10px 0px;
       }
       .Filter-student-list-container .heading h1 {
         font-size: 18px;
       }
       .Filter-student-list-container .Filter-student-list {
         width: 97%
       }
       .Filter-student-list-container .Filter-student-list .excel-download {
         gap: 8px;
         font-size: 14px;
       }
 
       
       /*Filter option style*/
       .Filter-student-list-container .Filter-list-container,
       .Filter-student-list-container .Filter-list-container .filter-options {
         width:98%;
         padding: 0px 10px;
         align-items: flex-start;
         flex-direction: row;
         border-radius: 15px;
         gap: 8px;
       }
       
       .Filter-student-list-container .Filter-list-container .filter-options .items {
         width:100%;
         height: auto;
         flex-direction: row;
         display: flex;
         align-items: center;
         gap: 10px;
         justify-content: center;
       }
       
       .Filter-student-list-container
         .Filter-list-container
         .filter-options
         .items:nth-child(3)
         select {
         width:100%;
       }
       
       .Filter-student-list-container
         .Filter-list-container
         .filter-options
         .items
         select {
         width:100%;
         height:39px;
       }
       
       .Filter-student-list-container
         .Filter-list-container
         .filter-options
         .items
         option {
         width:100%;
         height: 40px;
         font-size: 12px;
       }
       
       .Filter-student-list-container
         .Filter-list-container
         .filter-options
         .items
         input {
         width:100%;
         height:36px;
         font-size: 14px
       }
       
 
       .Filter-student-list-container
         .Filter-list-container
         .filter-options
         .items
         label {
         font-size: 15px;
       }
       
 
       
       .Filter-student-list-container
         .Filter-list-container
         .filter-options
         .items
         button {
         font-size: 16px;
         width: 100%;
       }
 
       
       .student-list-results table {
         width: 100%;
       }
       
       .student-list-results table th {
         padding:4px;
         font-size: 15px;
       }
       
       .student-list-results table td {
         padding:4px;
         font-size: 14px;
       }
}

/* Laptops and PCs (larger than 1200px) */
@media (min-width: 1200px) and (max-width: 1439.98px) {
    .Filter-student-list-container {
        gap: 10px;
       }
       .Filter-student-list-container .heading {
         width: 100%;
         height: 53px;
         padding: 10px 0px;
       }
       .Filter-student-list-container .heading h1 {
         font-size: 18px;
       }
       .Filter-student-list-container .Filter-student-list {
         width: 97%
       }
       .Filter-student-list-container .Filter-student-list .excel-download {
         gap: 8px;
         font-size: 14px;
       }
 
       
       /*Filter option style*/
       .Filter-student-list-container .Filter-list-container,
       .Filter-student-list-container .Filter-list-container .filter-options {
         width:98%;
         padding: 0px 10px;
         align-items: flex-start;
         flex-direction: row;
         border-radius: 15px;
         gap: 8px;
       }
       
       .Filter-student-list-container .Filter-list-container .filter-options .items {
         width:100%;
         height: auto;
         flex-direction: row;
         display: flex;
         align-items: center;
         gap: 10px;
         justify-content: center;
       }
       
       .Filter-student-list-container
         .Filter-list-container
         .filter-options
         .items:nth-child(3)
         select {
         width:100%;
       }
       
       .Filter-student-list-container
         .Filter-list-container
         .filter-options
         .items
         select {
         width:100%;
         height:39px;
       }
       
       .Filter-student-list-container
         .Filter-list-container
         .filter-options
         .items
         option {
         width:100%;
         height: 40px;
         font-size: 12px;
       }
       
       .Filter-student-list-container
         .Filter-list-container
         .filter-options
         .items
         input {
         width:100%;
         height:36px;
         font-size: 14px
       }
       
 
       .Filter-student-list-container
         .Filter-list-container
         .filter-options
         .items
         label {
         font-size: 15px;
       }
       
 
       
       .Filter-student-list-container
         .Filter-list-container
         .filter-options
         .items
         button {
         font-size: 16px;
         width: 100%;
       }
 
       
       .student-list-results table {
         width: 100%;
       }
       
       .student-list-results table th {
         padding:4px;
         font-size: 17px;
       }
       
       .student-list-results table td {
         padding:4px;
         font-size: 16px;
       }
}

/* Large screens (TVs and larger displays, 1440px and up) */
@media (min-width: 1440px) {
  /* Add your styles here */
}