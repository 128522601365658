@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.slider_container{
    position: relative;
    width:100vw;
    height:100vh;
    max-height:600px;
    margin:60px 0px 10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background:radial-gradient(#6e0505,#350202);
    z-index: 1;
    overflow: hidden;
}
.slider_container::after{
    content: '';
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background:url('../assets//slider/all_student.webp');
    background-position: center;
    z-index: -1;
    opacity: 0.2;
    filter: brightness(50%);
}
.slider_container .slider{
    width:90%;
    height: 100%;
    max-width: 1920px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.slider_container .slider .left{
    position: relative;
    top: 20%;
    width: 60%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.slider_container .slider .left .content{
    position: relative;
    width: 100%;
    height:auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.slider_container .slider .left .content h1{
    font-size: 3.5em;
    color: white;
    font-family: "Montserrat", sans-serif;
    
 
}

.promo-text {
    transition: opacity 0.5s ease-in-out;
  }
  
  .fade-in {
    opacity: 1;
  }
  
  .fade-out {
    opacity: 0;
  }
  
  
.slider_container .slider .left .content .sub-Content{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    align-items:flex-start;
    justify-content: flex-start;
}

.slider_container .slider .left .content .sub-Content ul{
    margin: 0;
    padding:0;
    display: flex;
}

.slider_container .slider .left .content .sub-Content ul li{
    font-size:1.5em;
    font-weight: bold;
    color: white;
    text-align: right;
    margin: 0px 20px;
    
    
}

.slider_container .slider .left .btn-container{
    margin-top: 30px;
    width: 160px;
    height:40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.slider_container .slider .left .btn-container .btn{
    border-radius: 10px;
    background: #e95d0c;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    color: white;
    width: 100%;
    height: 100%;
}
.slider_container .slider .left .btn-container .btn:hover{
    background: #ed7027; 
}

.slider_container .slider .right{
    position: relative;
    width:30%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content:flex-end;
}
.slider_container .slider .right .img{
    position: relative;
    top: 20%;
    width:100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.slider_container .slider .right .img img{
    width: 650px;
    animation:rightToLeft 2s ease-in-out;
}
@keyframes rightToLeft {
    0%{
transform: translateX(300px);
opacity: 0;
    }
    90%{
transform: translateX(0px);
    }
    100%{
        opacity: 1;
    }
}

/* choose form */

.slider_container .Selection-container{
    position: fixed;
    width:100vw;
    height:100vh;
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.slider_container .Selection-container .overlay{
    width: 100%;
    height: 100%;
    background: #000000f3;
    position: absolute;
}

.slider_container .Selection-container .Selection{
   width:500px;
   height:200px;
   border-radius: 10px;
   display: flex;
   background: #ffffff;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   z-index: 1;
   gap:10px;
}

.slider_container .Selection-container .Selection .heading{
  width:auto;
  height:auto;
  font-size: 14px;
  margin-bottom: 15px;
}
.slider_container .Selection-container .Selection .list{
    width: auto;
    display: flex;
    gap: 10px;
}
.slider_container .Selection-container .Selection .items{
  width: 130px;
  background: #620909;
  height:34px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  border-radius: 10px;
}

.slider_container .Selection-container .Selection .items:hover{
    background: transparent;
    border: #350202 2px solid;
    color: #350202;
}

#handleFromOption{
    display: none;
}
#handleFromOption:checked ~ .Selection-container{
    display: flex;
}

@media (max-width:574.89px) {

    .slider_container .slider{
        width:100%;
        height: 100%;
        max-width: 1920px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content:center;
    }
    .slider_container .slider .left{
        position: relative;
        top: 10%;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .slider_container .slider .left .content{
        position: relative;
        width: 90%;
        height:auto;
        display: flex;
        flex-direction: column;
        align-items:center;
        justify-content:center;
    }
    
    .slider_container .slider .left .content h1{
        font-size: 2em;
    }
    
    .slider_container .slider .left .content .sub-Content ul{
        flex-direction: column;
    }  
    .slider_container .slider .left .content .sub-Content ul li{
        font-size:1em;
        font-weight: bold;
        text-align: left;
        margin: 5px 20px;
    }
    
    .slider_container .slider .left .btn-container{
        margin-top: 10px;
        width: 90%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .slider_container .slider .left .btn-container .btn{
        width: 130px;
        height: 80%;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
        z-index: 500;
    }
    
    
    .slider_container .slider .right{
        position: relative;
        width:100%;
        height: 100%;
        display: flex;
        align-items:center;
        justify-content:center;
    }
    .slider_container .slider .right .img{
        position: relative;
        top: 10%;
        width:100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .slider_container .slider .right .img img{
        width: 390px;
    }

 
/* choose form */

.slider_container .Selection-container .Selection{
   max-width:500px;
   width: 90%;
   height:150px;
}

.slider_container .Selection-container .Selection .heading{
  font-size: 12px;
  margin-bottom: 12px;
}
    
}

@media (max-width:767.89px) and (min-width:575px) {

    .slider_container .slider{
        width:100%;
        height: 100%;
        max-width: 1920px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content:center;
    }
    .slider_container .slider .left{
        z-index: 100;
        position: relative;
        top: 20%;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .slider_container .slider .left .content{
        position: relative;
        width: 90%;
        height:auto;
        display: flex;
        flex-direction: column;
        align-items:center;
        justify-content:center;
    }
    
    .slider_container .slider .left .content h1{
        font-size: 2.3em;
    }
    
    .slider_container .slider .left .content .sub-Content ul{
        flex-direction: column;
    }  
    .slider_container .slider .left .content .sub-Content ul li{
        font-size:1.5em;
        font-weight: bold;
        text-align: left;
        margin: 5px 20px;
    }
    
    .slider_container .slider .left .btn-container{
        margin-top: 10px;
        width: 90%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .slider_container .slider .left .btn-container .btn{
        width: 160px;
        height: 80%;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        z-index: 500;
    }
    
    
    .slider_container .slider .right{
        position: relative;
        width:100%;
        height: 100%;
        display: flex;
        align-items:center;
        justify-content:center;
        z-index: -1;
    }
    .slider_container .slider .right .img{
        position: relative;
        top: 0%;
        width:100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .slider_container .slider .right .img img{
        width: 450px;
    }

     
/* choose form */

.slider_container .Selection-container .Selection{
    max-width:500px;
    width: 90%;
    height:150px;
 }
 
 .slider_container .Selection-container .Selection .heading{
   font-size: 12px;
   margin-bottom: 12px;
 }
   
     
}

@media (min-width:768px) and (max-width:993.98px) {

    .slider_container .slider{
        width:90%;
        height: 100%;
        max-width: 1920px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content:space-between;
    }
    .slider_container .slider .left{
        position: relative;
        top: 15%;
        width: 90%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }
    
    .slider_container .slider .left .content{
        position: relative;
        width: 100%;
        height:auto;
        display: flex;
        flex-direction: column;
        align-items:center;
        justify-content:center;
    }
    
    .slider_container .slider .left .content h1{
        font-size: 2.5em;
    }
    
    .slider_container .slider .left .content .sub-Content ul{
        flex-direction: column;
    }  
    .slider_container .slider .left .content .sub-Content ul li{
        font-size:1.7em;
        font-weight: bold;
        text-align: left;
        margin: 5px 20px;
    }
    
    .slider_container .slider .left .btn-container{
        margin-top: 30px;
        width: 90%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .slider_container .slider .left .btn-container .btn{
        width: 160px;
        height: 80%;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
    }
    
    
    .slider_container .slider .right{
        position: relative;
        width:10%;
        height: 100%;
        display: flex;
        align-items:flex-end;
        justify-content:flex-end;
    }
    .slider_container .slider .right .img{
        position: relative;
        top: 0%;
        left: -40px;
        width:100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .slider_container .slider .right .img img{
        width: 450px;
    }

     
}

@media (min-width:994px) and (max-width:1199.98px) {

    .slider_container .slider{
        width:90%;
        height: 100%;
        max-width: 1920px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content:space-between;
    }
    .slider_container .slider .left{
        position: relative;
        top: 15%;
        width: 90%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }
    
    .slider_container .slider .left .content{
        position: relative;
        width: 100%;
        height:auto;
        display: flex;
        flex-direction: column;
        align-items:center;
        justify-content:center;
    }
    
    .slider_container .slider .left .content h1{
        font-size: 3.2em;
    }
    
    .slider_container .slider .left .content .sub-Content ul{
        flex-direction: column;
    }  
    .slider_container .slider .left .content .sub-Content ul li{
        font-size:1.7em;
        font-weight: bold;
        text-align: left;
        margin: 5px 20px;
    }
    
    .slider_container .slider .left .btn-container{
        margin-top: 30px;
        width: 90%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .slider_container .slider .left .btn-container .btn{
        width: 160px;
        height: 80%;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
    }
    
    
    .slider_container .slider .right{
        position: relative;
        width:10%;
        height: 100%;
        display: flex;
        align-items:flex-end;
        justify-content:flex-end;
    }
    .slider_container .slider .right .img{
        position: relative;
        top: 0%;
        left: -40px;
        width:100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .slider_container .slider .right .img img{
        width: 450px;
    }

     
}

@media (min-width:1200px) and (max-width:1449.98px) {

    .slider_container .slider{
        width:90%;
        height: 100%;
        max-width: 1920px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content:space-between;
    }
    .slider_container .slider .left{
        position: relative;
        top: 15%;
        width: 90%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }
    
    .slider_container .slider .left .content{
        position: relative;
        width: 100%;
        height:auto;
        display: flex;
        flex-direction: column;
        align-items:flex-start;
        justify-content:center;
    }
    
    .slider_container .slider .left .content h1{
        font-size: 3em;
    }
    
    .slider_container .slider .left .content .sub-Content ul{
        flex-direction: column;
    }  
    .slider_container .slider .left .content .sub-Content ul li{
        font-size:1.7em;
        font-weight: bold;
        text-align: left;
        margin: 5px 20px;
    }
    
    .slider_container .slider .left .btn-container{
        margin-top: 30px;
        width: 90%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .slider_container .slider .left .btn-container .btn{
        width: 160px;
        height: 80%;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
    }
    
    
    .slider_container .slider .right{
        position: relative;
        width:10%;
        height: 100%;
        display: flex;
        align-items:flex-end;
        justify-content:flex-end;
    }
    .slider_container .slider .right .img{
        position: relative;
        top: 0%;
        left: -40px;
        width:100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .slider_container .slider .right .img img{
        width: 450px;
    }

     
}