@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
nav{
    top: 0;
    left: 0;
    position: fixed;
    width: 100vw;
    height: 60px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow:1px 1px 10px rgba(0, 0, 0, 0.233);
    z-index: 999;
}
#menubar_controller{
 display: none;       
}
nav .nav-container{
    width: 95%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

nav .nav-container .left{
    width: 160px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

nav .nav-container .left .img,
nav .nav-container .left .img a{
    width:100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

nav .nav-container .left .img img{
    height:90%;
}

nav .nav-container .right{
    height:100%;
    width:auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

nav .nav-container .right .menubar{
    height:100%;
    width:auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;

}

nav .nav-container .right .menubar .menus{
    height:100%;
    width:auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
 
nav .nav-container .right .menubar-overlay{
    position: fixed;
    display: none;
    top: 60px;
    left: 0;
    width: 100%;
    height:100vh;
    background: #000000d3;
    z-index: 998;
}

nav .nav-container .right .menubar .menus ul{
    height:100%;
    width:auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    list-style: none;
}

nav .nav-container .right .menubar .menus ul li{
    margin: 0px 15px;
}
nav .nav-container .right .menubar .menus ul li a{
    position: relative;
    text-decoration: none;
    font-size: 18px;
    color: #5f2c03;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    font-style: normal;

}

nav .nav-container .right .menubar .menus ul li a::after{
    content:'';
    position: absolute;
    top:110%;
    left: 0;
    width: 100%;
    height: 3px;
    background: #5f2c03;
    border-radius: 10px;
    transform: scaleX(0);
    transition: all 0.5s;
}


nav .nav-container .right .menubar .menus ul .active a{
    font-weight: bold;
}

nav .nav-container .right .menubar .menus ul li a:hover::after{
    transform: scaleX(1);
    transition: all 0.5s;
}



nav .nav-container .right .menubar .menus ul .active a::after{
content:'';
position: absolute;
top:110%;
left: 0;
width: 100%;
height: 3px;
background: #5f2c03;
border-radius: 10px;
transform: scale(1);
}
#menubar_controller:checked~.right .menubar-overlay{
   display: block;
}

@media (max-width:564.98px) {
   
    nav .nav-container{
        width: 95%;
        height: 100%;
        display: flex;
        justify-content: space-between;
    }
    
    nav .nav-container .left{
        width: 120px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    
    nav .nav-container .left .img{
        width:100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    
    nav .nav-container .left .img img{
        height:90%;
    }
    
    nav .nav-container .right{
        height:100%;
        width:auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    
    nav .nav-container .right .menubar{
        height:100%;
        width:50px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    nav .nav-container .right .menubar .menu-icon{
       width: 100%;
       height: 100%;
       display: flex;
       align-items: flex-end;
       justify-content: center;
       flex-direction: column;
       gap: 6px;
       cursor: pointer;
    }
    nav .nav-container .right .menubar .menu-icon .line{
        width: 100%;
        height: 4px;
        background: #720c0c;
        border-radius:20px;
        transition: all 0.5s;
    }

    nav .nav-container .right .menubar .menu-icon .line:nth-child(1){
        width: 60%;
    }
    nav .nav-container .right .menubar .menu-icon .line:nth-child(2){
        width: 60%;
    }
    nav .nav-container .right .menubar .menu-icon .line:nth-child(3){
        width: 70%;
    }
    /* check box  contoller */
    #menubar_controller:checked~.right .menubar .menu-icon .line:nth-child(1){
        transform: translate(0%,200%)rotate(45deg);
        width: 70%;
    }
    #menubar_controller:checked~.right .menubar .menu-icon .line:nth-child(3){
        transform: translate(-0%,-300%)rotate(-45deg);
    }
    #menubar_controller:checked~.right .menubar .menu-icon .line:nth-child(2){
        transform: translate(240%)
    }

    nav .nav-container .right .menubar .menus{
        position: fixed;
        height:100%;
        width:63%;
        background: #630000;
        top:60px;
        right: 0;
        transform: translateX(3000px);
        display: flex;
        align-items: center;
        justify-content:center;
        transition: all 1s linear;
        z-index:999;
    }
    nav .nav-container .right .menubar .menus::after{
        content:'';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background:url('../assets/other_img/wihte_stock.png');
        background-position: center;
        background-size: 250%;
        z-index: -1;
        opacity: .2;
    }
    nav .nav-container .right .menubar .menus ul{
        position: relative;
        top:10%;
        height:100%;
        width:auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0px;
        list-style: none;
    }
    
    nav .nav-container .right .menubar .menus ul li{
        margin: 10px 0px;
    }
    nav .nav-container .right .menubar .menus ul li a{
        position: relative;
        text-decoration: none;
        font-size: 18px;
        color: #ffffff;
        cursor: pointer;
    
    }
    
    nav .nav-container .right .menubar .menus ul li a::after{
        content:'';
        position: absolute;
        top:110%;
        left: 0;
        width: 100%;
        height: 3px;
        background: #ffffff;
        border-radius: 10px;
        transform: scaleX(0);
        transition: all 0.5s;
    }
    
    
    nav .nav-container .right .menubar .menus ul .active a{
        font-weight: bold;
    }
    
    nav .nav-container .right .menubar .menus ul li a:hover::after{
        transform: scaleX(1);
        transition: all 0.5s;
    }
    
    
    
    nav .nav-container .right .menubar .menus ul .active a::after{
    content:'';
    position: absolute;
    top:110%;
    left: 0;
    width: 100%;
    height: 3px;
    background: #ffffff;
    border-radius: 10px;
    transform: scale(1);
    }

#menubar_controller:checked ~ .right .menus{
    transform: translateX(0px);
    transition: all 1s linear;
}
}

@media (min-width:565px) and (max-width:767.98px) {
   
    nav .nav-container{
        width: 93%;
        height: 100%;
        display: flex;
        justify-content: space-between;
    }
    
    nav .nav-container .left{
        width: 160px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    
    nav .nav-container .left .img{
        width:100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    
    nav .nav-container .left .img img{
        height:70%;
    }
    
    nav .nav-container .right{
        height:100%;
        width:auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    
    nav .nav-container .right .menubar{
        height:100%;
        width:50px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    nav .nav-container .right .menubar .menu-icon{
       width: 100%;
       height: 100%;
       display: flex;
       align-items: flex-end;
       justify-content: center;
       flex-direction: column;
       gap: 6px;
       cursor: pointer;
    }
 nav .nav-container .right .menubar .menus{
        position: fixed;
        height:100%;
        width:50%;
        top:60px;
        right: 0;
        transform: translateX(3000px);
        display: flex;
        align-items: center;
        justify-content:center;
        transition: all 1s linear;
        z-index:999;
    }
    nav .nav-container .right .menubar .menus::after{
        content:'';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background:url('../assets/other_img/wihte_stock.png');
        background-position: center;
        background-size: 250%;
        z-index: -1;
        opacity: .2;
    }

    nav .nav-container .right .menubar .menu-icon .line{
        width: 100%;
        height: 4px;
        background: #720c0c;
        border-radius:20px;
        transition: all 0.5s;
    }

    nav .nav-container .right .menubar .menu-icon .line:nth-child(1){
        width: 60%;
    }
    nav .nav-container .right .menubar .menu-icon .line:nth-child(2){
        width: 60%;
    }
    nav .nav-container .right .menubar .menu-icon .line:nth-child(3){
        width: 70%;
    }

    /* check box  contoller */
    #menubar_controller:checked~.right .menubar .menu-icon .line:nth-child(1){
        transform: translate(0%,250%)rotate(45deg);
        width: 70%;
    }
    #menubar_controller:checked~.right .menubar .menu-icon .line:nth-child(3){
        transform: translate(-0%,-250%)rotate(-45deg);
    }
    #menubar_controller:checked~.right .menubar .menu-icon .line:nth-child(2){
        transform: translate(240%)
    }

    nav .nav-container .right .menubar .menus{
        position: fixed;
        height:100%;
        width:250px;
        background: #630000;
        top:60px;
       right:0px;
        transform: translateX(3000px);
        display: flex;
        align-items: center;
        justify-content:center;
        transition: all 1s linear;
        z-index:999;
    }
    
    nav .nav-container .right .menubar .menus ul{
        position: relative;
        top:10%;
        height:100%;
        width:auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0px;
        list-style: none;
    }
    
    nav .nav-container .right .menubar .menus ul li{
        margin: 10px 0px;
    }
    nav .nav-container .right .menubar .menus ul li a{
        position: relative;
        text-decoration: none;
        font-size: 18px;
        color: #ffffff;
        cursor: pointer;
    
    }
    
    nav .nav-container .right .menubar .menus ul li a::after{
        content:'';
        position: absolute;
        top:110%;
        left: 0;
        width: 100%;
        height: 3px;
        background: #ffffff;
        border-radius: 10px;
        transform: scaleX(0);
        transition: all 0.5s;
    }
    
    
    nav .nav-container .right .menubar .menus ul .active a{
        font-weight: bold;
    }
    
    nav .nav-container .right .menubar .menus ul li a:hover::after{
        transform: scaleX(1);
        transition: all 0.5s;
    }
    
    
    
    nav .nav-container .right .menubar .menus ul .active a::after{
    content:'';
    position: absolute;
    top:110%;
    left: 0;
    width: 100%;
    height: 3px;
    background: #ffffff;
    border-radius: 10px;
    transform: scale(1);
    }

#menubar_controller:checked ~ .right .menus{
    transform: translateX(0px);
    transition: all 1s linear;
}
}