.Amazing-kids-container {
  width: 100vw;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Amazing-kids-container .error-message {
  color: red;
  font-size: 16px;
  margin-top: 10px;
  text-align: center;
}

.Amazing-kids-container .Amazing-kids {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 1920px;
}
.Amazing-kids-container .Amazing-kids .heading {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: radial-gradient(rgb(169, 6, 6), #4e0303);
  color: white;
}
.Amazing-kids-container .Amazing-kids .student-list {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 50px 0px;
}
.Amazing-kids-container .Amazing-kids .student-list table {
  width:660px;
  text-align: center;
  border-collapse: collapse;
}
.Amazing-kids-container .Amazing-kids .student-list table tr th {
  width: auto;
  border: 1px white solid;
  background: #0a3f71;
  color: white;
  font-weight: 400;
  padding: 10px;
  font-size: 17px;
}
.Amazing-kids-container .Amazing-kids .student-list table tr td {
  width: auto;
  border: 1px white solid;
  color: white;
  font-weight: 400;
  padding: 10px;
  font-size: 16px;
}
.Amazing-kids-container .Amazing-kids .student-list table tr:nth-child(odd) td {
  background: #2373bf;
}
.Amazing-kids-container
  .Amazing-kids
  .student-list
  table
  tr:nth-child(even)
  td {
  background: #448bcc;
}

.Amazing-kids-container
  .Amazing-kids
  .student-list
  table
  tr
  td
  input[type="radio"] {
  margin: 0px 0px 0px 0px;
  zoom: 1.5;
  transform: translateY(0);
  cursor: pointer; /* Changes the cursor when hovering over the radio buttons */
}

.Amazing-kids-container
  .Amazing-kids
  .student-list
  table
  tr
  td
  input[type="radio"]:nth-child(1):checked {
  /* Style when radio button is checked */
  accent-color: green; /* Changes the color of the radio button when checked */
}

.Amazing-kids-container
  .Amazing-kids
  .student-list
  table
  tr
  td
  input[type="radio"]:nth-child(3):checked {
  /* Style when radio button is checked */
  accent-color: red; /* Changes the color of the radio button when checked */
}
.Amazing-kids-container
  .Amazing-kids
  .student-list
  table
  tr
  td:nth-child(4){
    display: flex;
    align-items: center;
    justify-content: center;
  }
/* When the Present radio button is checked */
.Amazing-kids-container
  .Amazing-kids
  .student-list
  table
  tr
  td
  input[type="radio"].Present_student_box:checked
  + label {
  color: rgb(0, 119, 0);
  background: #ffffff;
  padding:4px;
  border-radius: 5px;
}

/* When the Absent radio button is checked */
.Amazing-kids-container
  .Amazing-kids
  .student-list
  table
  tr
  td
  input[type="radio"].Absent_student_box:checked
  + label {
  color: red;
  background: #ffffff;
  padding:4px;
  border-radius: 5px;
}

.Amazing-kids-container .Amazing-kids .student-list table tr td {
  padding: 10px; /* Adds space inside the <td> around the radio buttons */
}

.Amazing-kids-container .Amazing-kids .student-list table tr label {
cursor: pointer;
color: white;
font-weight:500;
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.Amazing-kids-container .Amazing-kids .attendance-btn{
  width:180px;
  height:auto;
  padding:10px;
  color: white;
  background: #08345e;
  cursor: pointer;
  border-radius: 10px;
  text-align: center;
  margin:10px 0px;
  border: none;
  font-size: 18px;
  
}

.Amazing-kids-container .Amazing-kids .attendance-btn:hover{
  background: #0c569a;
  
}

/* Loading and sucess message style */

.Amazing-kids-container .loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.847);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.Amazing-kids-container .spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.Amazing-kids-container .success-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.895);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  flex-direction: column;
}

.Amazing-kids-container .checkmark {
  margin-bottom: 20px;
}

.Amazing-kids-container .checkmark svg {
  fill: none;
  stroke: #4CAF50;
  stroke-width: 5;
}

.Amazing-kids-container .success-message {
  color: rgb(0, 100, 7);
  font-size: 30px;
  font-weight: bold;
}



/* Common styles for all devices */

/* Small mobile devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
 
  .Amazing-kids-container .Amazing-kids .heading {
    width: 100%;
    min-height:80px;
    height: auto;
    font-size:10px;
    padding:5px 15px;
    text-align: center;
  }
  .Amazing-kids-container .Amazing-kids .student-list {
    padding: 10px 0px;
  }
  .Amazing-kids-container .Amazing-kids .student-list table {
    width:99%;
  }
  .Amazing-kids-container .Amazing-kids .student-list table tr th {
    padding: 5px 0px;
    font-size: 15px;
  }
  .Amazing-kids-container .Amazing-kids .student-list table tr td {
    padding: 2px;
    text-align: center;
    font-size: 15px;
  }

  
  .Amazing-kids-container
    .Amazing-kids
    .student-list
    table
    tr
    td
    input[type="radio"] {
    margin: 1px 0px 10px 10px;
  }

  .Amazing-kids-container
  .Amazing-kids
  .student-list
  table
  tr
  td:nth-child(4){
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Amazing-kids-container .Amazing-kids .student-list table tr td {
    padding: 9px; /* Adds space inside the <td> around the radio buttons */
  }

  .Amazing-kids-container .Amazing-kids .attendance-btn{
    border: none;
  }

 
  .Amazing-kids-container .checkmark {
    margin-bottom: 10px;
  }

  
  .Amazing-kids-container .success-message {
    font-size: 24px;
    text-align: center;
  }
}

/* Normal mobile devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
 
  .Amazing-kids-container .Amazing-kids .heading {
    width: 100%;
    min-height:80px;
    height: auto;
    font-size:10px;
    text-align: center;
  }
  .Amazing-kids-container .Amazing-kids .student-list {
    padding: 10px 0px;
  }
  .Amazing-kids-container .Amazing-kids .student-list table {
    width:98%;
  }
  .Amazing-kids-container .Amazing-kids .student-list table tr th {
    padding: 6px;
    font-size: 16px;
  }
  .Amazing-kids-container .Amazing-kids .student-list table tr td {
    padding: 6px;
    font-size: 15px;
  }

  
  .Amazing-kids-container
    .Amazing-kids
    .student-list
    table
    tr
    td
    input[type="radio"] {
    margin: 0px 0px 0px 0px;
  }

  .Amazing-kids-container .Amazing-kids .student-list table tr td {
    padding: 9px; /* Adds space inside the <td> around the radio buttons */
  }

  .Amazing-kids-container .Amazing-kids .attendance-btn{
    border: none;
  }

  .Amazing-kids-container
  .Amazing-kids
  .student-list
  table
  tr
  td:nth-child(4){
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
 
  .Amazing-kids-container .checkmark {
    margin-bottom: 10px;
  }

  
  .Amazing-kids-container .success-message {
    font-size: 24px;
    text-align: center;
  }
}

/* Tablets and iPads (portrait and landscape, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
 
  .Amazing-kids-container .Amazing-kids .heading {
    width: 100%;
    min-height:80px;
    height: auto;
    font-size:10px;
    text-align: center;
  }
  .Amazing-kids-container .Amazing-kids .student-list {
    padding: 10px 0px;
  }
  .Amazing-kids-container .Amazing-kids .student-list table {
    width:98%;
    max-width:600px;
  }
  .Amazing-kids-container .Amazing-kids .student-list table tr th {
    padding: 6px;
    font-size: 16px;
  }
  .Amazing-kids-container .Amazing-kids .student-list table tr td {
    padding: 6px;
    font-size: 15px;
  }

  
  .Amazing-kids-container
    .Amazing-kids
    .student-list
    table
    tr
    td
    input[type="radio"] {
    margin: 1px 0px 10px 20px;
  }

  .Amazing-kids-container .Amazing-kids .student-list table tr td {
    padding: 9px; /* Adds space inside the <td> around the radio buttons */
  }

  .Amazing-kids-container .Amazing-kids .attendance-btn{
    border: none;
  }

 
  .Amazing-kids-container .checkmark {
    margin-bottom: 10px;
  }

  
  .Amazing-kids-container .success-message {
    font-size: 24px;
    text-align: center;
  }
}

/* Tablets and iPads (landscape, larger than 992px) */
@media (min-width: 992px) and (max-width: 1199.98px) {

}

/* Laptops and PCs (larger than 1200px) */
@media (min-width: 1200px) and (max-width: 1439.98px) {

}

/* Large screens (TVs and larger displays, 1440px and up) */
@media (min-width: 1440px) {
  /* Add your styles here */
}