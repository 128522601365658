.examination-hero-container {
  position: relative;
  width: 100vw;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  background:#540808;
  z-index: 1;
}
.examination-hero-container::after{
 content: '';
 position: absolute;
 width: 100%;
 height: 100%;
background: url('../assets/exmination/group-kids-coloring.jpg');
background-repeat: no-repeat;
background-position:center;
background-size: cover;
background-attachment: fixed;
opacity: 0.2;
 z-index: -1;
}
.examination-hero-container .examination-hero {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.examination-hero-container .examination-hero .examination-date h1 {
  font-size: 60px;
  text-transform: capitalize;
  color: white;
}
.examination-hero-container .examination-hero .exam-date {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0px;
  gap: 10px;
}
.examination-hero-container .examination-hero .exam-date .exam-date-head h1 {
  color: white;
  text-transform: capitalize;
}
.examination-hero-container .examination-hero .exam-date .exam-time {
  padding: 0px 30px;
  border: 2px solid white;
  border-radius: 10px;
}
.examination-hero-container .examination-hero .exam-date .exam-time h1 {
  color: white;
  text-transform: capitalize;
  font-size: 20px;
  margin: 15px 0px 5px 0px;
}
.examination-hero-container .examination-hero .exam-date .exam-time h2 {
  color: white;
  text-transform: uppercase;
  font-size: 18px;
  margin: 15px 0px 5px 0px;
}
.examination-hero-container .examination-hero .wish p {
  color: white;
  text-transform: uppercase;
  font-size: 40px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  margin: 15px 0px 5px 0px;
}
/* google map */
.examination-hero-container .examination-hero .location {
width: auto;
display: flex;
flex-direction: column;
align-items:flex-start;
justify-content: center;
gap: 10px;
}
.examination-hero-container .examination-hero .location .address p{
font-size: 18px;
color: white;
text-transform: capitalize;
}

.examination-hero-container .examination-hero .location .google-map{
  width: auto;
  height: auto;
  display: flex;
  margin: 10px 0px;
}

.examination-hero-container .examination-hero .location .google-map .buttton{
  width: 160px;
  height:40px;
  border-radius: 10px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap:10px;
}

.examination-hero-container .examination-hero .location .google-map .buttton img{
width:22px;
}
.examination-hero-container .examination-hero .location .google-map .buttton  strong{
  color: #540808;
  font-size:18px;
}

/* Common styles for all devices */

/* Small mobile devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .examination-hero-container {
        position: relative;
        width: 100vw;
        height:450px;
        margin-top: 60px;
      }
      .examination-hero-container::after{
      background-position:center;
      background-size:cover;
      background-attachment:scroll;
      }

      .examination-hero-container .examination-hero .examination-date h1 {
        font-size:26px;
      }
      .examination-hero-container .examination-hero .exam-date {
        margin: 20px 0px;
        gap: 10px;
        padding: 0px 15px;
      }
      .examination-hero-container .examination-hero .exam-date .exam-date-head h1 {
        font-size: 20px;
      }

      .examination-hero-container .examination-hero .exam-date .exam-time h1 {
        font-size:16px;
        margin: 5px 0px 5px 0px;
      }
      .examination-hero-container .examination-hero .exam-date .exam-time h2 {
        font-size: 16px;
        margin: 5px 0px 5px 0px;
      }
      .examination-hero-container .examination-hero .wish p {
        font-size:20px;
        padding: 0px 20px;
        text-align: center;
        margin: 15px 0px 5px 0px;
      }
      
      /* google map */
      .examination-hero-container .examination-hero .location{
        gap: 0;
      }
  .examination-hero-container .examination-hero .location .address p{
  font-size: 16px;
  }
  
  .examination-hero-container .examination-hero .location .google-map{
    width: auto;
    height: auto;
    display: flex;
    margin: 10px 0px;
  }
  
  .examination-hero-container .examination-hero .location .google-map .buttton{
    width: 150px;
    height:38px;
    gap:10px;
  }
  
  .examination-hero-container .examination-hero .location .google-map .buttton img{
  width:20px;
  }
}

/* Normal mobile devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .examination-hero-container {
        position: relative;
        width: 100vw;
        height:400px;
        margin-top: 60px;
      }
      .examination-hero-container::after{
      background-position:center;
      background-size:cover;
      background-attachment:scroll;
      }

      .examination-hero-container .examination-hero .examination-date h1 {
        font-size:26px;
      }
      .examination-hero-container .examination-hero .exam-date {
        margin: 20px 0px;
        gap: 10px;
      }
      .examination-hero-container .examination-hero .exam-date .exam-date-head h1 {
        font-size: 20px;
      }

      .examination-hero-container .examination-hero .exam-date .exam-time h1 {
        font-size:16px;
        margin: 5px 0px 5px 0px;
      }
      .examination-hero-container .examination-hero .exam-date .exam-time h2 {
        font-size: 16px;
        margin: 5px 0px 5px 0px;
      }
      .examination-hero-container .examination-hero .wish p {
        font-size:20px;
        padding: 0px 20px;
        text-align: center;
        margin: 15px 0px 5px 0px;
      }

            /* google map */
            .examination-hero-container .examination-hero .location{
              gap: 0;
            }
        .examination-hero-container .examination-hero .location .address p{
        font-size: 16px;
        }
        
        .examination-hero-container .examination-hero .location .google-map{
          width: auto;
          height: auto;
          display: flex;
          margin: 10px 0px;
        }
        
        .examination-hero-container .examination-hero .location .google-map .buttton{
          width: 150px;
          height:38px;
          gap:10px;
        }
        
        .examination-hero-container .examination-hero .location .google-map .buttton img{
        width:20px;
        }
      
}

/* Tablets and iPads (portrait and landscape, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .examination-hero-container {
        position: relative;
        width: 100vw;
        height:400px;
        margin-top: 60px;
      }
      .examination-hero-container::after{
      background-position:center;
      background-size:cover;
      background-attachment:scroll;
      }

      .examination-hero-container .examination-hero .examination-date h1 {
        font-size:28px;
      }
      .examination-hero-container .examination-hero .exam-date {
        margin: 10px 0px;
        gap: 10px;
      }
      .examination-hero-container .examination-hero .exam-date .exam-date-head h1 {
        font-size: 20px;
      }

      .examination-hero-container .examination-hero .exam-date .exam-time h1 {
        font-size:20px;
        margin: 5px 0px 5px 0px;
      }
      .examination-hero-container .examination-hero .exam-date .exam-time h2 {
        font-size: 16px;
        margin: 5px 0px 5px 0px;
      }
      .examination-hero-container .examination-hero .wish p {
        font-size:26px;
        padding: 0px 20px;
        text-align: center;
        margin: 15px 0px 5px 0px;
      }

            /* google map */
            .examination-hero-container .examination-hero .location{
              gap: 0;
            }
        .examination-hero-container .examination-hero .location .address p{
        font-size: 16px;
        }
        
        .examination-hero-container .examination-hero .location .google-map{
          width: auto;
          height: auto;
          display: flex;
          margin: 10px 0px;
        }
        
        .examination-hero-container .examination-hero .location .google-map .buttton{
          width: 150px;
          height:38px;
          gap:10px;
        }
        
        .examination-hero-container .examination-hero .location .google-map .buttton img{
        width:20px;
        }
      
}

/* Tablets and iPads (landscape, larger than 992px) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    .examination-hero-container {
        position: relative;
        width: 100vw;
        height:400px;
        margin-top: 60px;
      }
      .examination-hero-container::after{
      background-position:center;
      background-size:cover;
      background-attachment:scroll;
      }

      .examination-hero-container .examination-hero .examination-date h1 {
        font-size:28px;
      }
      .examination-hero-container .examination-hero .exam-date {
        margin: 10px 0px;
        gap: 10px;
      }
      .examination-hero-container .examination-hero .exam-date .exam-date-head h1 {
        font-size: 20px;
      }

      .examination-hero-container .examination-hero .exam-date .exam-time h1 {
        font-size:20px;
        margin: 5px 0px 5px 0px;
      }
      .examination-hero-container .examination-hero .exam-date .exam-time h2 {
        font-size: 16px;
        margin: 5px 0px 5px 0px;
      }
      .examination-hero-container .examination-hero .wish p {
        font-size:26px;
        padding: 0px 20px;
        text-align: center;
        margin: 15px 0px 5px 0px;
      }
}

/* Laptops and PCs (larger than 1200px) */
@media (min-width: 1200px) and (max-width: 1439.98px) {
    .examination-hero-container {
        position: relative;
        width: 100vw;
        height:460px;
        margin-top: 60px;
      }
      .examination-hero-container::after{
      background-position:center;
      background-size:cover;
      background-attachment:scroll;
      }

      .examination-hero-container .examination-hero .examination-date h1 {
        font-size:38px;
      }
      .examination-hero-container .examination-hero .exam-date {
        margin: 10px 0px;
        gap: 10px;
      }
      .examination-hero-container .examination-hero .exam-date .exam-date-head h1 {
        font-size: 26px;
      }

      .examination-hero-container .examination-hero .exam-date .exam-time h1 {
        font-size:20px;
        margin: 5px 0px 5px 0px;
      }
      .examination-hero-container .examination-hero .exam-date .exam-time h2 {
        font-size: 16px;
        margin: 5px 0px 5px 0px;
      }
      .examination-hero-container .examination-hero .wish p {
        font-size:29px;
        padding: 0px 20px;
        text-align: center;
        margin: 15px 0px 5px 0px;
      }
}

/* Large screens (TVs and larger displays, 1440px and up) */
@media (min-width: 1440px) {
  /* Add your styles here */
}