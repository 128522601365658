/* src/Login.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  background: linear-gradient(to bottom right, #560707, #310101e9);
}

.login-container .login-form {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(81, 13, 13, 0.4);
  width: 100%;
  max-width: 400px;
}

.login-container h2 {
  text-align: center;
  color: #490505;
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: bold;
}

.login-container .input-group {
  margin-bottom: 20px;
}

.login-container .input-group label {
  display: block;
  margin-bottom: 8px;
  font-size: 18px;
  color: #710707;
  font-weight: 500;
}

.login-container .input-group input {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  background-color: #ffffff;
  color: #3e0606;
  border: 1px solid #420c0c;
  border-radius: 8px;
  transition: border 0.3s ease;
}

.login-container .input-group input:focus {
  border-color: #007bff;
  outline: none;
}

.login-container .error-message {
  color: #ff4d4d;
  font-size: 14px;
  text-align: center;
  margin: 10px;
}

.login-container .btn {
  width: 100%;
  height: 46px;
  padding: 14px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-container .btn:disabled {
  background-color: #555;
  cursor: not-allowed;
}

.login-container .btn:hover {
  background-color: #0056b3;
}

.login-container .btn:active {
  background-color: #003f80;
}

.login-container .spinner {
  border: 3px solid transparent;
  border-top: 3px solid #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  position: absolute;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Common styles for all devices */

/* Small mobile devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .login-container .login-form {
    width:85%;
  }
}

/* Normal mobile devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}
