.class-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  background: #f0f4f8;
  font-family: "Arial", sans-serif;
  color: #333;
  gap: 40px;
  padding: 20px;
}

/* Header styling */
.class-container h1 {
  font-size: 36px;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

/* Styling the list of classes */
.class-container .class-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 15px;
  animation: fadeIn 0.6s ease-in-out;
}

/* Individual list item styling */
.class-container .class-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Styling for the buttons */
.class-container .class-buttons {
  padding: 15px 30px;
  font-size: 18px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(0);
  opacity: 0;
  animation: fadeInButton 0.6s ease-in-out forwards;
}

/* Animation for the button (fade-in and slight move) */
.class-container .class-button:hover {
  background-color: #53ce59;
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Animation for the class buttons appearing */
.class-container .class-button:nth-child(1) {
  animation-delay: 0s;
}
.class-container .class-button:nth-child(2) {
  animation-delay: 0.1s;
}
.class-container .class-button:nth-child(3) {
  animation-delay: 0.2s;
}
.class-container .class-button:nth-child(4) {
  animation-delay: 0.3s;
}
.class-container .class-button:nth-child(5) {
  animation-delay: 0.4s;
}
.class-container .class-button:nth-child(6) {
  animation-delay: 0.5s;
}

/* Fade in animation for class list */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Fade in animation for class buttons */
@keyframes fadeInButton {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Common styles for all devices */

/* Small mobile devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  /* Header styling */
  .class-container {
    gap:0px;
    padding: 15px;
  }
  .class-container h1 {
    font-size: 28px;
    text-align: center;
    padding: 10px 0px;
  }

  /* Styling the list of classes */
  .class-container .class-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
    animation: fadeIn 0.6s ease-in-out;
  }

  /* Individual list item styling */
  .class-container .class-item {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Styling for the buttons */
  .class-container .class-buttons {
    width: 100%;
    padding: 12px;
  }
}

/* Normal mobile devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* Header styling */
  .class-container h1 {
    font-size: 28px;
    text-align: center;
    padding: 10px 0px;
  }

  /* Styling the list of classes */
  .class-container .class-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
    animation: fadeIn 0.6s ease-in-out;
  }

  /* Individual list item styling */
  .class-container .class-item {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Styling for the buttons */
  .class-container .class-buttons {
    width: 90%;
  }
}

/* Tablets and iPads (portrait and landscape, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* Header styling */
  .class-container h1 {
    font-size: 28px;
    text-align: center;
    padding: 10px 0px;
  }

  /* Styling the list of classes */
  .class-container .class-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
    animation: fadeIn 0.6s ease-in-out;
  }

  /* Individual list item styling */
  .class-container .class-item {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Styling for the buttons */
  .class-container .class-buttons {
    width: 90%;
  }
}
